import { Container, Grid } from '@mui/material'
import React from 'react'
import "../styles/css/About.css"
import Label from './Label'
import Pdf from '../styles/files/resume.pdf'
function About() {
  const image = require('../styles/images/IMG_8918 (1).jpg')
  return (
    <section data-scroll-section id='about-section'>
        <Container maxWidth='xl'>
        <Label num='01' label='ABOUT'/>
            <Grid container justifyContent='flex-end' alignItems='center'>
                <Grid item xs={6}>
                
                  <p className='about-description'>HELLO! MY NAME IS KELAN WU & WELCOME TO MY PAGE!</p>
                </Grid>
                <Grid item xs={6}>
                  <img className="image" align="right" src={image}></img>
                </Grid>
                <Grid item xs={12}>
                  <a className="resume" href={Pdf} target="_blank">RESUME</a>
                </Grid>
            </Grid>
          
            
        </Container>
    </section>
  )
}

export default About