import { Container, Grid } from "@mui/material";
import React from "react";
import "../styles/css/Experiences.css";
import Object from "./Object";
import Label from "./Label";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import SchoolIcon from '@mui/icons-material/School';
import Typography from "@mui/material/Typography";

function Experiences() {
  return (
    <section data-scroll-section id="exp-section">
      <Container maxWidth="xl">
        <Grid container>
          <Label num="03" label="Experiences" />
          {/* <Grid item xs={12}>
          <Object number = "00." company='Paycom' role = 'Software Engineering Intern' date='May 2024 - Aug. 2024' />
          <Object number = "01." company='RoviSys' role = 'Software Engineering Intern' date='Feb. 2024 - May 2024' />
          <Object number = '02.' company='University of houston' role='Summer Undergraduate Research Fellow' date='May 2023 - Aug. 2023' />
       
          
        </Grid> */}
          <Timeline position="left">
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                <p>Dallas, TX</p>
                <p>May 2024 - Aug. 2024</p>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot>
                <LaptopMacIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography variant="h6" component="span">
                  Paycom
                </Typography>
                <Typography>Software Engineering Intern</Typography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                variant="body2"
                color="text.secondary"
              >
                <p>Houston, TX</p>
                <p>Feb. 2024 - May 2024</p>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary">
                  <LaptopMacIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography variant="h6" component="span">
                  RoviSys
                </Typography>
                <Typography>Software Engineering Intern</Typography>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem>
            <TimelineOppositeContent
                sx={{ m: "auto 0" }}
                variant="body2"
                color="text.secondary"
              >
                <p>Houston, TX</p>
                <p>May 2023 - Aug. 2023</p>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color="primary" variant="outlined">
                  <SchoolIcon />
                </TimelineDot>
                <TimelineConnector sx={{ bgcolor: "secondary.main" }} />
              </TimelineSeparator>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography variant="h6" component="span">
                  University of Houston
                </Typography>
                <Typography>Summer Undergraduate Research Fellow</Typography>
              </TimelineContent>
            </TimelineItem>
            
          </Timeline>
        </Grid>
      </Container>
    </section>
  );
}

export default Experiences;
