import React, { useEffect } from 'react'
import '../styles/css/Main.css';


import '../styles/css/locomotive-scroll.css'


import Title from '../components/Title';
import useLocoScroll from '../hooks/useLocoScroll';
import Directory from '../components/Directory';
import Skills from '../components/Skills';
import Projects from '../components/Projects';
import Experiences from '../components/Experiences';
import Footer from '../components/Footer';
import About from '../components/About';
import NavBar from '../components/NavBar';


function Main() {
 
  useLocoScroll();
  useEffect(()=> {
    document.title = "PORTFOLIO";
  }, []);
  return (
    
    <body>
      <div data-load-container>
       <div className="main-container" id="main-container" data-scroll-container>
          <NavBar/>
          <Title />
          <Directory />
          
          <About />
          <Skills />

          
          <Experiences /> 
          <Projects />
          
          <Footer />
        </div>  
      </div>
    </body>
  )
}

export default Main