import { Container, Grid } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import '../styles/css/Projects.css'
import TabObject from './TabObject';
import Label from './Label';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
function Projects() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
const statPadder = require('../styles/images/StatPadder.jpg')
const resource = require('../styles/images/resource.jpg')
const roty = require('../styles/images/roty.jpg')
const simplystatefarm = require('../styles/images/simplystatefarm.jpg')
  return (
    <section data-scroll-section id='projects-section'>
      <br/>
      <Container maxWidth='xl'>
        <Label label='Projects' num='04' />
        <br />
        <Box sx={{ width: '100%' }}>
        {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}> */}
          <Tabs className='tabs'  value={value} onChange={handleChange} variant="scrollable"  allowScrollButtonsMobile aria-label="basic tabs example" TabIndicatorProps={{style: {backgroundColor:"black"}}}centered>
            <Tab label={<span className='tab-label'>Simply StateFarm</span>} {...a11yProps(0)} />
            <Tab label={<span className='tab-label'>Resource Visualization</span>} {...a11yProps(1)} />
            <Tab label={<span className='tab-label'>NBA ROTY Predictor</span>} {...a11yProps(2)} />
            <Tab label={<span className='tab-label'>Stat Padder</span>} {...a11yProps(3)} />
          </Tabs>
        {/* </Box> */}
          <TabPanel value={value} index={0}>
            <TabObject name="Simply StateFarm" project='HackUTD X (2023)' image = {simplystatefarm} description="Simply StateFarm is a forward-thinking project designed to simplify and expedite the often complex and time-consuming process of obtaining insurance for small businesses. By leveraging cutting-edge technology and user-friendly interfaces, this initiative is set to revolutionize the insurance industry for small enterprises. It seeks to provide a seamless and hassle-free experience for business owners, ensuring that they can easily access the insurance coverage they need to protect their operations and assets. With Simply StateFarm, the future of small business insurance is as straightforward as its name suggests." tech = 'React, ExpressJS, MongoDB, NodeJS' repo='https://github.com/Aalhad-Pathare/HackUTD-X' />
          </TabPanel>
          <TabPanel value={value} index={1}>
              <TabObject name='Resource Visualization' project='CodeRed Hackathon (2022)' image = {resource} description='Resource Visualization is a web app developed during the CodeRed Hackathon, designed to serve as a powerful data analytics tool for examining nationwide gas, petroleum, and oil data. This innovative application allows users to visualize and analyze key metrics, trends, and patterns within the industry. With its user-friendly interface and advanced data visualization capabilities, Resource Visualization empowers researchers, analysts, and industry professionals to gain valuable insights and make data-driven decisions in the field of gas, petroleum, and oil.' tech='Python, Flask, JavaScript, HTML, CSS, Bootstrap' repo='https://github.com/kelanwu17/codered-hackathon'/>
          </TabPanel>
          <TabPanel value={value} index={2}>
              <TabObject name='NBA ROTY Predictive Model' project='Personal Project' image={roty} description='The NBA Rookie of the Year Prediction project utilizes machine learning techniques, including linear regression, ridge regression, and lasso regression, to forecast the recipient of the NBA Rookie of the Year award. By leveraging rookie data spanning from the 1980 season to the 2022 season, the project aims to predict the Rookie of the Year for the 2023 season. The implementation involves popular libraries such as pandas, numpy, sci-kit learn, and beautiful soup for data manipulation, analysis, and modeling.' tech='Python, NumPy, pandas, beautifulsoup, matplotlib, scikit learn' repo='https://github.com/kelanwu17/predicting-roty'/>
          </TabPanel>
          <TabPanel value={value} index={3}>
              <TabObject name='Stat Padder' project='Personal Project' image={statPadder} description='Stat Padder is a web application that provides NBA statistics for any player who has competed in the league. Users can easily search for their favorite players and access comprehensive data on their career performance, including points, rebounds, assists, steals, blocks, and shooting percentages. The app offers a user-friendly interface, allowing fans and analysts to quickly gather valuable insights and make informed assessments of player contributions.' tech='Python, Flask, JavaScript, HTML, CSS, Bootstrap' repo='https://github.com/kelanwu17/stat-padder'/>
          </TabPanel>
        </Box>
       
         
           
    </Container>
    </section>
  )
}

export default Projects