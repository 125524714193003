import { Container, Grid } from '@mui/material'
import React from 'react'
import "../styles/css/Skills.css"
import Label from './Label'


function Skills() {
  return (
    <section data-scroll-section className="container" id="tech-section">
        <Container maxWidth='xl' >
          <br></br>
        <Label num='02' label='Tech & Skills'/>
        <br></br>
            <Grid container direction='row' alignItems='flex-start' justifyContent='space-evenly'>
              <Grid item>
                Languages
                <ul>
                  <li>C/C++</li>
                  <li>Python</li>
                  <li>JavaScript/TypeScript</li>
                  <li>R</li>
                  <li>HTML/CSS</li>
                  <li>SQL</li>
                </ul>
              </Grid>
              <Grid item>
                Frameworks
                <ul>
                  
                  <li>Ionic</li>
                  <li>Bootstrap</li>
                  <li>React</li>
                  <li>Flask</li>
                </ul>
              </Grid>
              <Grid item>
                Database
                <ul>
                  
                  
                  <li>PostgreSQL</li>
                  <li>MongoDB</li>
                </ul>
              </Grid>
            </Grid>
        </Container>
       

    </section>
  )
}

export default Skills