import React from 'react'

import { Container, Grid } from '@mui/material'
import '../styles/css/Title.css'





function Title() {
  
  return (
<section data-scroll-section>

<Container maxWidth="xl" className="box" id='top'>
  <Grid container >
    <Grid item xs={12}>
      <div className='space'></div>
    </Grid>
    <Grid item xs={12} style={{textAlign:"center"}} >
      <span className='title-box'>PORTFOLIO</span>
    </Grid>


</Grid>

</Container>


<br/>
</section>
  )
}

export default Title