import { Grid } from '@mui/material'
import React from 'react'
import "../styles/css/Label.css"
function Label(props) {
  return (
    <Grid container justifyContent='flex-start' alignItems='flex-end' spacing={3}>
          {/* <Grid item >
            <span className="section-label-number">{props.num}</span>
          </Grid> */}
          <Grid item>
            <span className="section-label">{props.label}</span>
          </Grid>
    </Grid>
    
  )
}

export default Label