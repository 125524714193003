import LocomotiveScroll from "locomotive-scroll";
import { useEffect } from "react";
import '../styles/css/locomotive-scroll.css';

export default function useLocoScroll(){
    useEffect(()=> {
        

        const scrollEl = document.querySelector('#main-container');
        const locoScroll = new LocomotiveScroll({
            el: scrollEl,
            smooth: true,
            multiplier: 1,
            class: 'is-reveal'
        })
    }, []);
} 