import React from 'react'
import '../styles/css/Footer.css'
import { Container, Grid } from '@mui/material'

function Footer() {
  return (
    <section data-scroll-section id='footer'>
        
          {/* <Grid container direction = 'column' justifyContent='center' alignItems='center'>
            <Grid item xs={12}>
              <p>Scroll to top</p>
            </Grid>
            <Grid item xs = {12}>
              <a data-scroll-to href='#top'><svg className = 'arrow' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg"><path d="M12.001,1.993C6.486,1.994,2,6.48,2,11.994c0.001,5.514,4.487,10,10,10c5.515,0,10.001-4.486,10.001-10 S17.515,1.994,12.001,1.993z M12,19.994c-4.41,0-7.999-3.589-8-8c0-4.411,3.589-8,8.001-8.001c4.411,0.001,8,3.59,8,8.001 S16.412,19.994,12,19.994z"></path><path d="M12.001 8.001L7.996 12.006 11.001 12.006 11.001 16 13.001 16 13.001 12.006 16.005 12.006z"></path></svg></a>
            </Grid>
            <Grid item  xs={12}>
              <p>Developed by Kelan Wu</p>
            </Grid>
            <Grid item xs={12}>
              <p>email: ckwu3@cougarnet.uh.edu</p>
            </Grid>
            <Grid item xs={12}>
              <a href='https://kelanwu17.github.io' target='_blank' rel='noreferrer'>PORTFOLIO V1</a>
            </Grid>
           
          </Grid> */}
          <Container maxWidth="xl">
              <Grid container justifyContent='space-between' >
                <Grid item xs={12} style={{textAlign:'center'}} className="footer-header"> 
                  <span>PORTFOLIO</span>
                </Grid>
                <Grid item xs={4}>
                  <p className='reach'>REACH OUT TO ME HERE:</p>
                  <p>ckwu3@cougarnet.uh.edu</p>
                </Grid>
                <Grid item xs={4} style={{textAlign:"center"}}>
                  <p><svg stroke="currentColor" fill="currentColor" stroke-width="0" version="1.2" baseProfile="tiny" viewBox="0 0 24 24" height="1.25em" width="1.25em" xmlns="http://www.w3.org/2000/svg"><path d="M21.821 12.43c-.083-.119-2.062-2.944-4.793-4.875-1.416-1.003-3.202-1.555-5.028-1.555-1.825 0-3.611.552-5.03 1.555-2.731 1.931-4.708 4.756-4.791 4.875-.238.343-.238.798 0 1.141.083.119 2.06 2.944 4.791 4.875 1.419 1.002 3.205 1.554 5.03 1.554 1.826 0 3.612-.552 5.028-1.555 2.731-1.931 4.71-4.756 4.793-4.875.239-.342.239-.798 0-1.14zm-9.821 4.07c-1.934 0-3.5-1.57-3.5-3.5 0-1.934 1.566-3.5 3.5-3.5 1.93 0 3.5 1.566 3.5 3.5 0 1.93-1.57 3.5-3.5 3.5zM14 13c0 1.102-.898 2-2 2-1.105 0-2-.898-2-2 0-1.105.895-2 2-2 1.102 0 2 .895 2 2z"></path></svg></p>
                </Grid>
                <Grid item xs={4} style={{textAlign:"right"}}>
                  <a data-scroll-to href='#top'><svg className = 'arrow' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="3em" width="3em" xmlns="http://www.w3.org/2000/svg"><path d="M12.001,1.993C6.486,1.994,2,6.48,2,11.994c0.001,5.514,4.487,10,10,10c5.515,0,10.001-4.486,10.001-10 S17.515,1.994,12.001,1.993z M12,19.994c-4.41,0-7.999-3.589-8-8c0-4.411,3.589-8,8.001-8.001c4.411,0.001,8,3.59,8,8.001 S16.412,19.994,12,19.994z"></path><path d="M12.001 8.001L7.996 12.006 11.001 12.006 11.001 16 13.001 16 13.001 12.006 16.005 12.006z"></path></svg></a>
                  <p >Return to Top</p>
                </Grid>
                <Grid item xs={6} direction='column'>
                  <div>
                    <a href='https://linkedin.com/in/kelanwu' target='_blank'>LinkedIn</a>
                  </div>
                  <div>
                   <a href='https://github.com/kelanwu17' target='_blank'>GitHub</a>
                  </div>
                  <div>
                    <a href='https://instagram.com/kelanwu_' target='_blank'>Instagram</a>
                  </div>
                
                </Grid>
                <Grid item xs={6}style={{textAlign:"right"}}>
                  <p><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm4.81 12.81a6.72 6.72 0 0 1-2.17 1.45c-.83.36-1.72.53-2.64.53-.92 0-1.81-.17-2.64-.53-.81-.34-1.55-.83-2.17-1.45a6.773 6.773 0 0 1-1.45-2.17A6.59 6.59 0 0 1 1.21 8c0-.92.17-1.81.53-2.64.34-.81.83-1.55 1.45-2.17.62-.62 1.36-1.11 2.17-1.45A6.59 6.59 0 0 1 8 1.21c.92 0 1.81.17 2.64.53.81.34 1.55.83 2.17 1.45.62.62 1.11 1.36 1.45 2.17.36.83.53 1.72.53 2.64 0 .92-.17 1.81-.53 2.64-.34.81-.83 1.55-1.45 2.17zM4 6.8v-.59c0-.66.53-1.19 1.2-1.19h.59c.66 0 1.19.53 1.19 1.19v.59c0 .67-.53 1.2-1.19 1.2H5.2C4.53 8 4 7.47 4 6.8zm5 0v-.59c0-.66.53-1.19 1.2-1.19h.59c.66 0 1.19.53 1.19 1.19v.59c0 .67-.53 1.2-1.19 1.2h-.59C9.53 8 9 7.47 9 6.8zm4 3.2c-.72 1.88-2.91 3-5 3s-4.28-1.13-5-3c-.14-.39.23-1 .66-1h8.59c.41 0 .89.61.75 1z"></path></svg></p>
                </Grid>
                <Grid item xs={4} >
                  <div>
                  <p>Copyright © 2023 Kelan Wu</p>
                  </div>
                </Grid>
                <Grid item xs={4}style={{textAlign:'center'}} > 
                  <p>Dev. w/ React & MUI</p>
                </Grid>
                <Grid item xs={4} style={{textAlign:'right'}}>
                  <p><a href='https://kelanwu17.github.io' target='_blank'>Portfolio V1</a></p>
                </Grid>
              </Grid>
          </Container>
            
            
        
    </section>
  )
}

export default Footer