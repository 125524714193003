import React from 'react'
import { Container} from '@mui/material'
import Stack from '@mui/material/Stack'
 import '../styles/css/Main.css'

function Directory() {
  return (
    <section data-scroll-section className="directory-container">
        <Container maxWidth='xl'>

            <Stack direction={{sm:'column', md:'row'}} justifyContent={{sm:'flex-start', md:'flex-start'}} alignItems="stretch" spacing={1.5} className="directory-container directory-font" >
              <a className='directory-item' id = 'about' href='#about-section' data-scroll-to >
                <span>01</span>
                <p>ABOUT</p>
              </a>
            
              <a  className='directory-item' href='#tech-section' id = 'tech' data-scroll-to ><span>02</span><p>TECH & SKILLS</p></a>
              <a className='directory-item' href='#exp-section' id = 'exp' data-scroll-to ><span>03</span><p>EXPERIENCES</p></a>
              <a className='directory-item' href='#projects-section' id = 'projects' data-scroll-to ><span>04</span><p>PROJECTS</p></a>
              
            </Stack>
            <br></br>
            </Container>
            
        </section>
  )
}

export default Directory